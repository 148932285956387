import React, { Component } from "react";
import "./style.css";
import config from "../../config/index";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import Apple from "../../assests/appleStore.svg";
import Google from "../../assests/googlePlay.svg";
import ReactGA from "react-ga4";
ReactGA.initialize("G-BN1DW2YXGX");

export default class index extends Component {
  handleStoreClick = (store) => {
    ReactGA.event({
      category: "Download",
      action: `Clicked ${store} Download`,
      label: `User clicked ${store} store download`,
    });
  };

  render() {
    return (
      <div style={{ height: "350px" }}>
        <Container>
          <center>
            <div>
              <span id="getStartText">Get started with ExamHUB today!</span>
            </div>
            <div>
              <span id="getStartSubText">
                Download our app to transform your exam preparation into an
                engaging and fun experience. Available now{" "}
              </span>
            </div>
            <div>
              <span id="getStartSubText">
                on the App Store and Google Play.
              </span>
            </div>
            <div style={{ paddingTop: "20px" }}>
              <a
                href={config.APP_STORE}
                target="_blank"
                rel="noreferrer"
                onClick={() => this.handleStoreClick("Apple")}
              >
                <Image src={Apple} alt="image" />
              </a>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <a
                href={config.GOOGLE_PLAY}
                target="_blank"
                rel="noreferrer"
                onClick={() => this.handleStoreClick("Google Play")}
              >
                <Image src={Google} alt="image" />
              </a>
            </div>
          </center>
        </Container>
      </div>
    );
  }
}
