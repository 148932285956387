import "./App.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import LandingPage from "./pages/landingPage";

function App() {
  return (
    <div className="App">
      <LandingPage />
    </div>
  );
}

export default App;
