import React, { Component } from "react";
import config from "../../config/index";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import AppStore from "../../assests/appStoreColor.svg";
import PlayStore from "../../assests/playStoreColor.svg";
import FaceBook from "../../assests/facebookColor.svg";
import WhatApp from "../../assests/whatAppColor.svg";
import Youtube from "../../assests/youtubeColor.svg";
import ThreePlus from "../../assests/3plus.svg";
import Slingshot from "../../assests/slingshot.svg";
import Stanford from "../../assests/stanford.svg";
import Spiralation from "../../assests/spiralation.svg";
import ProductHunt from "../../assests/productHunt.svg";
import "./style.css";
import ReactGA from "react-ga4";
ReactGA.initialize("G-BN1DW2YXGX");

export default class index extends Component {
  handleButtonClick = (platform) => {
    ReactGA.event({
      category: "Footer Interaction",
      action: `Clicked ${platform} Button`,
      label: `${platform} button clicked in footer`,
    });
  };
  render() {
    return (
      <div id="footerContainer">
        <div style={{ paddingTop: "50px" }}>
          <Container>
            <div className="footerRow">
              <div className="footerRow_1">
                <div className="column_2" style={{ paddingLeft: "25px" }}>
                  <div style={{ textAlign: "left" }}>
                    <span id="footerHeading">OUR PRODUCTS</span>
                  </div>
                  <div style={{ textAlign: "left", paddingTop: "30px" }}>
                    <ul className="no-bullets">
                      <li id="List">ExamHUB</li>
                      <li id="List">CourseHUB</li>
                      <li id="List">FEDES AI</li>
                      <li id="List">KnowledgeHUB</li>
                    </ul>
                  </div>
                </div>
                <div className="column_3">
                  <div style={{ textAlign: "left" }}>
                    <span id="footerHeading">INFORMATION</span>
                  </div>
                  <div style={{ textAlign: "left", paddingTop: "30px" }}>
                    <ul className="no-bullets">
                      <li id="List">Terms & Conditions</li>
                      <li id="List">FAQ</li>
                      <li id="List">Features</li>
                      <li id="List">About us</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div style={{ display: "flex", paddingTop: "20px" }}>
                <div className="moble_column_4">
                  <div style={{ textAlign: "left", paddingLeft: "25px" }}>
                    <Button id="contactUsBtn">Contact Us</Button>{" "}
                  </div>
                </div>
                <div className="moble_column_5">
                  <div style={{ textAlign: "right", paddingLeft: "80px" }}>
                    <div>
                      <span id="contactUsText">+94770508710</span>
                    </div>
                    <div id="contactUsText">contact@fonixss.com</div>
                  </div>
                </div>
              </div>
              <center>
                <div className="footerRow_2" style={{ paddingTop: "20px" }}>
                  <div className="column_2_1">
                    <div>
                      <a
                        href={config.APP_STORE}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => this.handleButtonClick("App Store")}
                      >
                        <Image
                          src={AppStore}
                          className="social-icon"
                          alt="image"
                        />
                      </a>
                      &nbsp;&nbsp;&nbsp;
                      <a
                        href={config.GOOGLE_PLAY}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => this.handleButtonClick("Google Play")}
                      >
                        <Image
                          src={PlayStore}
                          className="social-icon"
                          alt="image"
                        />
                      </a>
                      &nbsp;&nbsp;&nbsp;
                      <a
                        href={config.FACEBOOK}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => this.handleButtonClick("Facebook")}
                      >
                        <Image
                          src={FaceBook}
                          className="social-icon"
                          alt="image"
                        />
                      </a>
                      &nbsp;&nbsp;&nbsp;
                      <a
                        href=" "
                        onClick={() => this.handleButtonClick("WhatsApp")}
                      >
                        <Image src={WhatApp} className="social-icon" />
                      </a>
                      &nbsp;&nbsp;&nbsp;
                      <a
                        href={config.YOUTUBE}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => this.handleButtonClick("YouTube")}
                      >
                        <Image
                          src={Youtube}
                          className="social-icon"
                          alt="image"
                        />
                      </a>
                      &nbsp;&nbsp;&nbsp;
                    </div>
                  </div>
                </div>
              </center>
              <center>
                <div className="iconFooter">
                  <div style={{ display: "flex" }}>
                    <Image src={Slingshot} alt="image" />
                    &nbsp;&nbsp;&nbsp;
                    <Image src={Stanford} alt="image" />
                    &nbsp;&nbsp;&nbsp;
                    <Image src={Spiralation} alt="image" />
                    &nbsp;&nbsp;&nbsp;
                    <a
                      href={config.PRODUCT_HUNT}
                      target="_blank"
                      rel="noreferrer"
                      onClick={() => this.handleButtonClick("Product Hunt")}
                    >
                      <Image src={ProductHunt} alt="image" />
                    </a>
                  </div>
                </div>
              </center>
            </div>

            <div style={{ display: "flex", paddingTop: "20px" }}>
              <div className="mobile_column_3_1">
                <div style={{ textAlign: "left" }}>
                  <div>
                    <span id="footerText">If you didn’t find the products</span>
                  </div>
                  <div>
                    <span id="footerText">you are interested in or have </span>
                  </div>
                  <div>
                    <span id="footerText">questions?</span>
                  </div>
                </div>
              </div>
              <div className="mobile-column_3_2">
                <div>
                  <div>
                    <Image src={ThreePlus} alt="image" />
                    &nbsp;&nbsp;&nbsp;
                    <span id="proudlyText">Suitable for all age groups</span>
                  </div>
                </div>
              </div>
            </div>
            <center>
              <div>
                <div>
                  <div id="textAreaDiv">
                    <div id="textSection">
                      <div style={{ textAlign: "left" }}>
                        <span id="formText">YOUR EMAIL</span>
                      </div>
                      <div style={{ textAlign: "left" }}>
                        <span id="formText">example@example.com</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </center>
            <div style={{ textAlign: "left", marginTop: "40px" }}>
              <span id="mailText">
                © 2024 — Copyright Fonix Software Solution Pvt Ltd.
              </span>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}
