import React, { useEffect, useState } from "react";
import io from "socket.io-client";
import "./style.css";
import CountUp from "react-countup";
import Container from "react-bootstrap/Container";
import config from "../../config";

const socket = io(config.API_URL);

const RegisterComponent = () => {
  const [registerCount, setRegisterCount] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showPlus, setShowPlus] = useState(false);
  const [previousCount, setPreviousCount] = useState(null);

  useEffect(() => {
    socket.on("user_count_updated", (data) => {
      const newCount = data.totalMobileUserCount;
      if (previousCount !== null && newCount > previousCount) {
        setShowPlus(true);
        setTimeout(() => setShowPlus(false), 1000);
      }
      setPreviousCount(newCount);
      setRegisterCount(newCount);
      setLoading(false);
    });
  }, [previousCount]);

  return (
    <Container className="regCount">
      <div className="wall-of-love">
        <h3>❤️ WALL OF LOVE</h3>
        <h2>Loved by educators</h2>
        <h2>since 2021</h2>
        <div className="flip-display">
          {loading ? (
            <div className="loader-count"></div>
          ) : (
            registerCount
              .toString()
              .split("")
              .map((digit, index) => (
                <div key={index} className="flip-box">
                  <CountUp separator="," end={digit} duration={0.01} />
                </div>
              ))
          )}
        </div>
        {showPlus && <div className="plus-icon">+1</div>}
        <p className="trusted-label">TRUSTED USERS</p>
      </div>
    </Container>
  );
};

export default RegisterComponent;
