import React, { Component } from "react";
import CountUp from "react-countup";
import "./style.css";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import ExpressionImg from "../../assests/expression.svg";
import DownloadImg from "../../assests/downloads.svg";
import ReviewsImg from "../../assests/star.svg";
import Animal from "../../assests/appFeatureIcon.svg";

export default class index extends Component {
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const animalContainer = document.querySelector(".animal-container-statics");
    const scrollPosition = window.scrollY + window.innerHeight;
    const animalPosition = animalContainer.offsetTop;

    if (scrollPosition >= animalPosition) {
      animalContainer.classList.add("scrolled");
    } else {
      animalContainer.classList.remove("scrolled");
    }
  };
  render() {
    return (
      <Container>
        <center>
          <div className="staticMain">
            <div className="static_1">
              <div id="staticsBundle">
                <div>
                  <Image src={ExpressionImg} rounded alt="image" />
                </div>
                <div>
                  <span id="staticsValue">
                    <CountUp end={800} duration={3} separator="," prefix="+" />K
                  </span>
                </div>
                <div>
                  <span id="staticText">Expression</span>
                </div>
              </div>
            </div>
            <div className="static_2">
              <div id="staticsBundle">
                <div>
                  <Image src={DownloadImg} rounded alt="image" />
                </div>
                <div>
                  <span id="downloadValue">
                    <CountUp end={850} duration={3} separator="," prefix="+" />K
                  </span>
                </div>
                <div>
                  <span id="staticText">Downloads</span>
                </div>
              </div>
              <div className="vertical-line1"></div>
            </div>

            <div className="static_3">
              <div id="staticsBundle">
                <div>
                  <Image src={ReviewsImg} rounded alt="image" />
                </div>
                <div>
                  <span id="staticsValue">
                    <CountUp
                      end={2.87}
                      duration={3}
                      separator=","
                      prefix="+"
                      decimals={2}
                    />
                    K
                  </span>
                </div>
                <div>
                  <span id="staticText">Reviews</span>
                </div>
                <div className="vertical-line2"></div>
              </div>
            </div>
          </div>
        </center>
        <div className="animal-container-statics">
          <img src={Animal} className="animal-image-statics" alt="Animal" />
        </div>
      </Container>
    );
  }
}
