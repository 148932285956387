const config = {
  APP_STORE: "https://apps.apple.com/lk/app/examhub/id1567751663",
  GOOGLE_PLAY:
    "https://play.google.com/store/apps/details?id=com.fonixss.examhublk&hl=en",
  FACEBOOK: "https://web.facebook.com/examhublk/?_rdc=1&_rdr",
  YOUTUBE: "https://www.youtube.com/watch?v=XXO-y5LWLn4",
  WHATAPP: "https://wa.link/b7hi6y",
  API_URL: "https://api.examhub.lk/",
  PRODUCT_HUNT:
    "https://www.producthunt.com/posts/examhub-gamified-exam-app?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-examhub&#0045;gamified&%230045;exam&%230045;app",
};

export default config;
