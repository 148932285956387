import React, { Component } from "react";
import "./style.css";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import UnderLine from "../../assests/underLine.svg";
import DualIphoneImg from "../../assests/dualIphone.svg";
import Image_1 from "../../assests/image_1.svg";
import Image_2 from "../../assests/image_2.svg";
import Image_3 from "../../assests/image_3.svg";
import Gaming from "../../assests/gamingHand.svg";
import Meeting from "../../assests/meeting.svg";
import Cambridge from "../../assests/cambridge.svg";
import SriLanka from "../../assests/srilanka.svg";
import Edexcel from "../../assests/edexcel.svg";
import Animal from "../../assests/appF.svg";

export default class Index extends Component {
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const animalContainer = document.querySelector(".animal-container-app");
    const scrollPosition = window.scrollY + window.innerHeight;
    const animalPosition = animalContainer.offsetTop;

    if (scrollPosition >= animalPosition) {
      animalContainer.classList.add("scrolled");
    } else {
      animalContainer.classList.remove("scrolled");
    }
  };

  render() {
    return (
      <div id="appFeatureContainer">
        <center>
          <div style={{ paddingTop: "20px" }}>
            <div>
              <span id="appFeatureText">App Features</span>
            </div>
            <div style={{ marginTop: "-20px" }}>
              <Image src={UnderLine} alt="image" />
            </div>
          </div>
        </center>
        <div id="appFeature" style={{ display: "flex" }}>
          <div className="appLeftPane">
            <div>
              <Image src={Image_1} rounded fluid alt="image" />
            </div>
            <div>
              <Image
                src={Image_2}
                rounded
                style={{ rotate: "14.94 deg" }}
                fluid
                alt="image"
              />
              <Image src={DualIphoneImg} rounded fluid />
              <Image
                src={Image_3}
                rounded
                style={{ rotate: "-15.71 deg" }}
                fluid
                alt="image"
              />
            </div>
          </div>
          <div className="appRightPane">
            <div style={{ textAlign: "left" }}>
              <Container>
                <div>
                  <span id="RightPaneText">Exam Papers / Lessons with</span>
                </div>
                <div>
                  <span id="RightPaneText">Gaming Experience</span>
                </div>
                <div>
                  <span id="appSubText">
                    "Revolutionize your exam prep with ExamHUB’s innovative
                    gamified platform. Engage, compete, and excel while turning
                    learning into an exciting, interactive experience."
                  </span>
                </div>
                <div style={{ display: "flex", paddingTop: "20px" }}>
                  <div>
                    <Image src={Gaming} roundedCircle fluid alt="image" />
                  </div>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <div>
                    <span id="gameTitleText">Gamified Learning Experience</span>
                    <div>
                      <span id="gameSubTitleText">
                        Turn exam prep into an exciting adventure. Engage with
                        interactive quizzes and challenges that make studying
                        enjoyable and effective.
                      </span>
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", paddingTop: "20px" }}>
                  <div>
                    <Image src={Meeting} roundedCircle fluid alt="image" />
                  </div>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <div>
                    <span id="gameTitleText">Connect and Compete</span>
                    <div>
                      <span id="gameSubTitleText">
                        Join friends, track progress, and rise on the
                        leaderboards. Foster a community of learners,
                        challenging and supporting each other
                      </span>
                    </div>
                  </div>
                </div>

                <div style={{ paddingTop: "20px" }}>
                  <span id="SyllabusAvailableText">Syllabus available</span>
                </div>

                <div className="Apptest">
                  <div style={{ paddingTop: "20px" }}>
                    <Image src={Cambridge} fluid alt="image" />
                    &nbsp;&nbsp;&nbsp;
                    <Image src={SriLanka} fluid alt="image" />
                    &nbsp;&nbsp;&nbsp;
                    <Image src={Edexcel} fluid alt="image" />
                  </div>
                </div>
              </Container>
            </div>
          </div>
        </div>
        <div className="animal-container-app">
          <img src={Animal} className="animal-image-app" alt="Animal" />
        </div>
      </div>
    );
  }
}
