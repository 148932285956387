import React, { Component } from "react";
import "./style.css";
import Image from "react-bootstrap/Image";
import Logo from "../../assests/Logo.svg";
import Scroll from "../../assests/scrollUp.svg";
import ReactGA from "react-ga4";
ReactGA.initialize("G-BN1DW2YXGX");

export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNavbarOpen: false,
      showScrollButton: false,
    };
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.scrollToSection = this.scrollToSection.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  toggleNavbar() {
    this.setState({ isNavbarOpen: !this.state.isNavbarOpen });
    ReactGA.event({
      category: "Navigation",
      action: "Toggle Navbar",
      label: this.state.isNavbarOpen ? "Close Navbar" : "Open Navbar",
    });
  }
  scrollToSection(id) {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      ReactGA.event({
        category: "Navigation",
        action: `Scroll to ${id}`,
        label: `User clicked ${id} section`,
      });
    }
  }

  scrollToTop(id) {
    window.scrollTo({ top: 0, behavior: "smooth" });
    ReactGA.event({
      category: "Navigation",
      action: "Scroll to Top",
      label: "User clicked scroll to top",
    });
  }

  handleScroll() {
    if (window.scrollY > 300) {
      this.setState({ showScrollButton: true });
    } else {
      this.setState({ showScrollButton: false });
    }
  }

  render() {
    return (
      <div className="continer" id="headerContainer">
        <header>
          <nav className="navbar navbar-expand-lg navbar-light">
            <center>
              <div id="fedesLogoDiv">
                <Image src={Logo} alt="logo" id="Logo" className="img-fluid" />
              </div>
            </center>
            <div className="container-fluid">
              <button
                className="navbar-toggler ms-auto"
                type="button"
                onClick={this.toggleNavbar}
                style={{ float: "right" }}
              >
                <i class="bi bi-list" style={{ color: "#004A8E" }}></i>
              </button>
              <div
                className={`collapse navbar-collapse ${
                  this.state.isNavbarOpen ? "show" : ""
                }`}
              >
                <ul
                  className="navbar-nav me-auto ms-auto mb-3 mb-lg-0"
                  id="list"
                >
                  <li className="nav-item active">
                    <a
                      className="nav-link"
                      href="#1"
                      id="navName"
                      onClick={() => this.scrollToSection("Header")}
                    >
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="#1"
                      id="navName"
                      onClick={() => this.scrollToSection("AppFeature")}
                    >
                      Features
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="#1"
                      id="navName"
                      onClick={() => this.scrollToSection("CheckOut")}
                    >
                      About Us
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="#1"
                      id="navName"
                      onClick={() => this.scrollToSection("Footer")}
                    >
                      Contact
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="#2"
                      id="downloadApp_a"
                      onClick={() => this.scrollToSection("DownloadApp")}
                    >
                      <span id="downloadApp">Download App</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>
        {this.state.showScrollButton && (
          <div className="scroll-to-top" onClick={this.scrollToTop}>
            <Image src={Scroll} />
            <div>
              <span id="backToText">Back to Top </span>
            </div>
          </div>
        )}
      </div>
    );
  }
}
