import React, { useState, useEffect } from "react";
import "./style.css";
import config from "../../config/index";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Youtube from "../../assests/youtube.svg";
import Spinner from "react-bootstrap/Spinner";

export default function Index() {
  const [youtubeUrl, setYoutubeUrl] = useState("");
  const [isIframeLoading, setIsIframeLoading] = useState(true);

  useEffect(() => {
    fetch("/youtube.json")
      .then((response) => response.json())
      .then((data) => {
        setYoutubeUrl(data.youtubeUrl);
      })
      .catch((error) => console.error("Error fetching YouTube URL:", error));
  }, []);

  const handleIframeLoad = () => {
    setIsIframeLoading(false);
  };

  return (
    <div id="checkOutContainer">
      <center>
        <div style={{ paddingTop: "110px" }}>
          <span id="checkOutText">Check out ExamHub</span>
        </div>
        <div>
          <Container>
            <span id="subTitle">
              Explore ExamHUB and experience innovative gamified learning. Boost
              your exam prep with engaging quizzes, progress tracking, and a
              supportive community. Start your journey to success today!
            </span>
          </Container>
        </div>
        <br />
        <br />
        <br />
        <div>
          <div className="video-wrapper">
            {isIframeLoading && (
              <div className="loading-animation">
                <Spinner animation="border" role="status" style={{color:"#fff"}}>
                </Spinner>
              </div>
            )}
            {youtubeUrl && (
              <iframe
                src={youtubeUrl}
                title="YouTube video player"
                onLoad={handleIframeLoad}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{ display: isIframeLoading ? "none" : "block" }}
              ></iframe>
            )}
          </div>
        </div>
        <div style={{ paddingTop: "60px" }} id="bottomText">
          <div>
            <span id="YoutueText_1">More Videos on</span>
          </div>
          <div>
            <a href={config.YOUTUBE} target="_blank" rel="noreferrer">
              <Image src={Youtube} alt="YouTube logo" />
            </a>
            &nbsp;&nbsp;&nbsp;
            <span id="YoutueText_2">FONIX Software Solutions PVT LTD</span>
          </div>
        </div>
      </center>
    </div>
  );
}
