import React, { Component } from "react";
import "./style.css";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import StarImg from "../../assests/star.svg";
import Underline from "../../assests/underLine.svg";
import TestimonialsData from "../../assests/Json/testimonials.json";

export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      testimonials: [],
      activeIndex: 0,
    };
  }

  componentDidMount() {
    this.setState({ testimonials: TestimonialsData });
    this.interval = setInterval(this.cycleCards, 3000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  cycleCards = () => {
    this.setState((prevState) => ({
      activeIndex: (prevState.activeIndex + 1) % this.state.testimonials.length,
    }));
  };

  getCardClass = (index) => {
    const { activeIndex } = this.state;
    if (index === activeIndex) return "animatedImage front";
    if (index === (activeIndex + 1) % this.state.testimonials.length)
      return "animatedImage middle";
    return "animatedImage back";
  };
  render() {
    const { testimonials } = this.state;
    return (
      <div id="testimonoialsContainer">
        <div className="leftPane">
          <div style={{ textAlign: "left" }}>
            <Container>
              <div>
                <span id="TestimonialsText">TESTIMONIALS</span>
              </div>
              <div>
                <Image src={Underline} rounded />
              </div>
              <div>
                <span id="HeaderText">What Gamers say</span>
              </div>
              <div>
                <span id="HeaderText">about Us.</span>
              </div>
              <div>
                <span id="subtitleText">
                  Discover what our users love about ExamHUB. Hear from students
                  who have transformed their exam prep into a fun, engaging, and
                  successful journey with our gamified platform.
                </span>
              </div>
              <div style={{ display: "flex", paddingTop: "20px" }}>
                <Image src={StarImg} rounded />
                <div>
                  <span id="test_static">+2.87K</span>
                  <div>
                    <span id="test_reviews">Reviews</span>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </div>
        <div className="rightPane">
          <div className="cardWrapper">
            {testimonials.map((testimonial, index) => (
              <div key={index} className={this.getCardClass(index)}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={testimonial.image}
                    alt={`${testimonial.name} profile`}
                    className="profileImg"
                  />
                </div>
                <p className="description">{testimonial.description}</p>
                <div style={{ marginTop: "30px" }}>
                  <h5 className="name">{testimonial.name}</h5>
                  <h6 className="location">{testimonial.location}</h6>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
