import React, { Component } from "react";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import "./style.css";

export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      awards: [],
    };
  }

  componentDidMount() {
    this.fetchAwardsData();
    this.startScrolling();
  }

  fetchAwardsData = async () => {
    try {
      const response = await fetch("/awards.json");
      const data = await response.json();
      this.setState({ awards: data }, () => {
        const scrollContent = document.querySelector(".scrollContent");
        scrollContent.innerHTML += scrollContent.innerHTML;
      });
    } catch (error) {
      console.error("Error fetching awards data:", error);
    }
  };

  startScrolling() {
    const scrollContent = document.querySelector(".scrollContent");
    const scrollSpeed = 2;
    let scrollPosition = 0;

    setInterval(() => {
      scrollPosition -= scrollSpeed;
      if (Math.abs(scrollPosition) >= scrollContent.scrollWidth / 2) {
        scrollPosition = 0;
      }
      scrollContent.style.transform = `translateX(${scrollPosition}px)`;
    }, 30);
  }

  render() {
    const { awards } = this.state;

    return (
      <div className="AwardContainer">
        <Container>
          <center>
            <div className="AwardHeaderTextDiv">
              <span className="AwardHeaderText">
                A Nationally and Internationally Award-Winning Education App
              </span>
            </div>
            <div className="awardsImgScroll">
              <div className="scrollContent">
                {awards.map((award, index) => (
                  <div className="awardFrame" key={index}>
                    <Image src={award.image} alt="awardLogo" />
                    <div>
                      <span className="awardName">{award.name}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </center>
        </Container>
      </div>
    );
  }
}
