import React, { Component } from "react";
import "./style.css";
import config from "../../config/index";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import Image_1 from "../../assests/header_sideshow_1.svg";
import Image_2 from "../../assests/header_sideshow_2.svg";
import Image_3 from "../../assests/header_sideshow_3.svg";
import Image_4 from "../../assests/header_sideshow_4.svg";
import Apple from "../../assests/appleStore.svg";
import Google from "../../assests/googlePlay.svg";
import Cambridge from "../../assests/cambridge.svg";
import Edexcel from "../../assests/edexcel.svg";
import Users from "../../assests/users.svg";
import Animal_1 from "../../assests/headerAnim.svg";
import Animal_2 from "../../assests/headerAnim2.svg";
import ReactGA from "react-ga4";
ReactGA.initialize("G-BN1DW2YXGX");

export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [Image_1, Image_2, Image_3, Image_4],
      positions: ["front", "right", "back", "left"],
    };
  }

  componentDidMount() {
    this.interval = setInterval(this.rotateImages, 3000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  rotateImages = () => {
    this.setState((prevState) => {
      const newPositions = [...prevState.positions];
      newPositions.unshift(newPositions.pop());
      return { positions: newPositions };
    });
  };

  handleStoreClick = (store) => {
    ReactGA.event({
      category: "Download",
      action: `Clicked ${store} Download`,
      label: `User clicked ${store} store download`,
    });
  };

  render() {
    const { images, positions } = this.state;

    return (
      <div id="headerContiner">
        <div className="headerLeftPane">
          <div id="headerTextArea">
            <Container>
              <div>
                <span id="educationAppText">#1 EDUCATION APP</span>
              </div>
              <div>
                <span id="headerText">Level Up Your Exam</span>
              </div>
              <div>
                <span id="headerText">PREPARATION</span>
              </div>
              <div>
                <span id="headerText">With</span>&nbsp;&nbsp;&nbsp;
                <span id="examhubText">ExamHub</span>
              </div>
              <div>
                <span id="headerSubTitleText">
                  Level up your exam preparation with ExamHUB! Experience
                  gamified learning, track progress, and ace your exams. Join
                  now, 800,000+ students using ExamHUB to make studying fun and
                  effective
                </span>
              </div>
              <div style={{ paddingTop: "20px" }}>
                <a
                  href={config.APP_STORE}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => this.handleStoreClick("Apple")}
                >
                  <Image src={Apple} alt="image" />
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <a
                  href={config.GOOGLE_PLAY}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => this.handleStoreClick("Google Play")}
                >
                  <Image src={Google} alt="image" />
                </a>
              </div>
              <div>
                <div style={{ paddingTop: "50px" }}>
                  <Image src={Cambridge} fluid alt="image" />
                  &nbsp;&nbsp;&nbsp;
                  <Image src={Edexcel} fluid alt="image" />
                </div>
              </div>
              <div style={{ paddingTop: "15px" }}>
                <span id="SyllabusAreIncludedText">Syllabus are included</span>
              </div>
              <div style={{ paddingTop: "15px" }}>
                <span>
                  <Image src={Users} fluid alt="image" /> &nbsp;&nbsp;&nbsp;
                  <span id="userText">850k+ Users Downloaded app</span>
                </span>
              </div>
            </Container>
          </div>
        </div>

        <div className="headerRightPane">
          <div>
            <div className="image-rotator-container">
              {images.map((image, index) => (
                <div
                  className={`image-container ${positions[index]}`}
                  key={index}
                >
                  <Image src={image} alt={`Image ${index + 1}`} />
                </div>
              ))}

              <div className="animal-container-header">
                <img
                  src={Animal_1}
                  className="animal-image-header"
                  alt="Animal"
                />
              </div>
              <div className="animal-container-header-2">
                <img
                  src={Animal_2}
                  className="animal-image-header-2"
                  alt="Animal"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
