import React, { Component } from "react";
import "./style.css";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import SS_1 from "../../assests/header_sideshow_1.svg";
import SS_2 from "../../assests/header_sideshow_2.svg";
import SS_3 from "../../assests/header_sideshow_3.svg";
import SS_4 from "../../assests/header_sideshow_4.svg";
import SS_5 from "../../assests/header_sideshow_5.svg";
import SS_6 from "../../assests/header_sideshow_6.svg";
import SS_7 from "../../assests/header_sideshow_5.svg";
import DottedFrame from "../../assests/frame.svg";
import UnderLine from "../../assests/underLine.svg";
import Animal from "../../assests/applicationSS.svg";

export default class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [SS_1, SS_2, SS_3, SS_4, SS_5, SS_6, SS_7],
      positions: [
        "left-1",
        "left-2",
        "left-3",
        "front",
        "right-1",
        "right-2",
        "right-3",
      ],
    };
  }

  componentDidMount() {
    this.interval = setInterval(this.rotateImages, 3000);
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    window.removeEventListener("scroll", this.handleScroll);
  }

  rotateImages = () => {
    this.setState((prevState) => {
      const newPositions = [...prevState.positions];
      newPositions.push(newPositions.shift());
      return { positions: newPositions };
    });
  };

  handleScroll = () => {
    const animalContainer = document.querySelector(".animal-container");
    const scrollPosition = window.scrollY + window.innerHeight;
    const animalPosition = animalContainer.offsetTop;

    if (scrollPosition >= animalPosition) {
      animalContainer.classList.add("scrolled");
    } else {
      animalContainer.classList.remove("scrolled");
    }
  };

  render() {
    const { images, positions } = this.state;
    return (
      <div style={{ marginTop: "120px", height: "900px" }}>
        <center>
          <Container>
            <div className="animal-container">
              <img src={Animal} className="animal-image" alt="Animal" />
            </div>
            <br />
            <div>
              <span id="applicationScreenshotsText">
                Application screenshots
              </span>
            </div>
            <div style={{ marginTop: "-10px" }}>
              <Image src={UnderLine} rounded alt="image" />
            </div>

            <div>
              <span id="subTitleInApplication">
                Get a glimpse of the ExamHUB experience! Explore the intuitive
                design, engaging features, and interactive elements that make
                studying fun and effective. See it in action below!
              </span>
            </div>

            <div>
              <div className="applicationSS-container">
                {images.map((image, index) => (
                  <div
                    className={`applicationScreenshots-image-container ${positions[index]}`}
                    key={index}
                  >
                    <Image src={image} alt={`Image ${index + 1}`} />
                  </div>
                ))}
              </div>
            </div>
            <div style={{ marginTop: "50px" }}>
              <Image src={DottedFrame} rounded alt="image" />
            </div>
          </Container>
        </center>
      </div>
    );
  }
}
