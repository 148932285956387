import React, { Component } from "react";
import NavBar from "../component/navBar/index";
import Header from "../component/header/index";
import Statics from "../component/statics/index";
import AppFeature from "../component/appFeature/index";
import CheckOut from "../component/checkOut/index";
import ApplicationScreenshot from "../component/applicationScreenshot/index";
import TestImonials from "../component/testimonials/index";
import GetStart from "../component/getStart/index";
import Footer from "../component/footer/index";
import MobileFooter from "../component/mobilefooter/index";
import RegisterCount from "../component/userCount/index";
import Awards from "../component/awards/index";
import ExamHUBLogo from "../assests/examHUB.svg";
import Image from "react-bootstrap/Image";
import "./style.css";

export default class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPreloaderVisible: true,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ isPreloaderVisible: false });
    }, 2000);
  }

  render() {
    const { isPreloaderVisible } = this.state;

    return (
      <div>
        <div id="preloader" className={isPreloaderVisible ? "" : "hidden"}>
          <div className="loader"></div>
          <div className="preloader-text">
            <div>ExamHUB</div>
            <div style={{ marginTop: "-50px" }}>
              <Image
                className="preloader-logo"
                src={ExamHUBLogo}
                alt="ExamHUB Logo"
              />
            </div>
          </div>
        </div>

        <section id="NavBar">
          <NavBar />
        </section>
        <section id="Header">
          <Header />
        </section>
        <section id="Awards">
          <Awards />
        </section>
        <section id="Count">
          <RegisterCount />
        </section>
        <section id="Statics">
          <Statics />
        </section>
        <section id="AppFeature">
          <AppFeature />
        </section>
        <section id="CheckOut">
          <CheckOut />
        </section>
        <section id="ApplicationScreenshot">
          <ApplicationScreenshot />
        </section>
        <section id="TestImonials">
          <TestImonials />
        </section>
        <section id="DownloadApp">
          <GetStart />
        </section>
        <section id="Footer" className="desktop-footer">
          <Footer />
        </section>
        <section id="Footer" className="mobile-footer">
          <MobileFooter />
        </section>
      </div>
    );
  }
}
